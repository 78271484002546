/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  overviewContainer: {
    bg: `omegaDarker`,
    borderRadius: `xl`,
    py: 6,
    px: [4, 2]
  },
  teamContainer: {
    'img, .block-overlay': {
      borderRadius: `xl`
    }
  }
}
